.Checkbox {

  .container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font: var(--font-line-m);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkmark {
          background-color: var(--primary-color);

          &:after {
            display: block;
          }
        }
      }
    }

    .checkmark {
      transition: all 0.2s ease-in-out;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;

      &:after {
        content: "";
        position: absolute;
        display: none;

        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:hover {

      input {
        &:checked {
          ~ .checkmark {
            background-color: lighten(#63ABBE, 5%);
          }
        }

        ~ .checkmark {
          background-color: #ccc;
        }
      }
    }
  }
}
