@import "reset";
@import "variables/global";
@import "themes/normal";
@import "themes/dark";

html {
  min-height: 100vh;
  font-family: var(--font-family-main);
}

body {
  min-height: 100vh;
  overflow-y: auto;
  overscroll-behavior-y: none;
}

.app {
  font: var(--font-m) !important;
}

svg {
  transition: all 0.2s linear !important;
}

.cont::-webkit-scrollbar {
  width: 0;
}

.active-card {
  border-top: 2px solid #1976d2;
}

.media-control-layer {
  display: none;
}

.gray {
  color: #7A7A7A
}

.errorMessage {
  font: var(--font-s);
  color: var(--secondary-color);
  margin-top: 5px;
  text-align: center;

}

//react-datepicker__month-container {
//  width: 100%;
//}

//pull-to-refresh
.ptr-element {
  position: absolute;
  top: 60px;
  //top: 0;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  border-radius: 50%;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  animation-delay: 0s !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}

a {
  color: var(--link-color);
  font: var(--font-m);
  text-decoration: none;
}

.bottomSheet {
  height: 75vh;
}
