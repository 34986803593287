.accountList {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 20px;
}

.accCard {
  width: 392px;
  height: 100%;
  padding: 20px;
  margin-top: 10px;

  &_mobile {
    margin: 15px 16px 0px;
  }

}

.balancePay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 49px 10px 50px;
  font-weight: 500;
  border-bottom: solid 1px #D9D9D9;

  p {
    font-size: 16px;
    line-height: 28px;
    color: #767676;;
  }

  .rubBalance {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 4px;
    font-size: 32px;

    span {
      font-size: 15px;
    }
  }
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.addTitle {
  font-size: 15px;
  color: #767676;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ruleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 273px;
}

.addContract {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 392px;
  height: 123px;
  gap: 20px;
  margin-top: 10px;
  cursor: pointer;
  p {
    font-size: 14px;
  }
}

.payButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

@media (max-width: 1200px) {
  .accCard {
    width: 100%;
  }
  .accountList {
    margin-left: 16px;
  }
  .addContract {
    margin-left: 16px;
  }
}

@media (max-width: 700px) {
  .accCard {
    width: 100%;
  }
  .accountList {
    margin: 0px 0px;
    gap: 0;
  }
  .addContract {
    max-width: none;
    margin: 15px 16px 0px;
    padding: 0px;
  }
}

// mobile styles
.dogovorMobile {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.buttonToGeneral {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F4F4;
  border-radius: 6px;
  cursor: pointer;
}

.mobileList {
  display: flex;
  flex-direction: row;
  gap: 15px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.addressFull {
  color: #767676;
  line-height: 21px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.mobilePay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  border-top: solid 1px #D9D9D9;
  padding: 17px 10px 0px;

  p {
    font-size: 14px;
  }

  .rubBalance {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 4px;
    font-size: 24px;

    span {
      font-size: 15px;
    }
  }
}


