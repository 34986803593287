.wrapper {
  padding: 1%;
  max-width: 600px;
  .superInstruction{
    display: flex;
    justify-content: space-between;
  }

  .superTitle{
    font-weight: 500;
    font-size: 22px;
  }

  a{
    text-decoration: underline;
  }

  a,
  .title,
  .text {
    font-size: 16px;
  }

  .title{
    font-weight: 600;
  }

  .text{
    font-weight: 400;
  }

  .mt {

    margin-top: 15px;
  }
}


