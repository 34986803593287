.CameraPreview {
  overflow: hidden;
  height: 100%;
  margin-bottom: 5px;
  .title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1vh;
  }
  .titleSvg{
    display: flex;
    align-items: flex-end;
  }
  .preview {
    position: relative;
    margin-top: 5px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    user-select: none;
    .img {
      object-fit: cover;
      width: 100%;
      height: 180px;
      background-size: cover;
      border-radius: 5px;
    }
    .lockStyle:hover{
      background: none;
      svg{
        path {
        stroke: var(--primary-color);          
      }
    }
    }
    .controls {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      //position: absolute;
      //left: 50%;
      //top: 50%;
      //transform: translate(-50%, -50%);
      .playWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .playWrapper:hover{
        background: none;
        svg{
          path {
          stroke: var(--primary-color);          
        }
      }
    }
      .play {
        width: 70px;
        cursor: pointer;
      }

      .lock {
        width: 70px;
        path {
          stroke: white;          
          transition: fill 0.2s ease-in-out;
        }        
      }
    }
  }
}
