.NavigationBar {
  position: fixed;
  height: 80px;
  z-index: 2;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(166, 166, 166, 0.15);
}
.MobileNavigationBar{
  position: fixed;
  height: 60px;
  z-index: 2;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(166, 166, 166, 0.15);
}

.position {
  position: relative;
}

.blockMenu {
  display: flex;
  align-items: center;
  height: 80px;

  img {
    margin-right: 10px;
  }

  &__ItemBlock {
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 30px;
    height: 100%;
    color: #767676;
    font-size: 18px;
    padding: 12px;
    border-radius: 5px;

    &:hover {
      background-color: lighten(#9ECCD8, 17%);
    }
  }

  .active {
    font-weight: 700;
    color: #2A8293;
    line-height: 30px;
  }
}

.mobBlockMenu {
  display: flex;
  align-items: center;

  &__btn {
    display: flex;
    position: relative;
    justify-content: flex-start;
    transform: rotateZ(180deg);
    svg path {
      fill: #000000;
    }
  }
  &__btn::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    cursor: pointer;
  }
  &__title {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    color: #000000;
    font-size: 20px;
  }
}

.mobBlockGreet {
  color: #000000;
  font-size: 20px;
}

.blockAvatar {
  position: absolute;
  right: 0;
  padding: 0 10px;
  border-radius: 5px;
  height: 51px;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: lighten(#9ECCD8, 17%);
  }

  .blockAvatar__title {
    color: #959595;
    font-size: 20px;
  }

  .blockAvatar__arrow path {
    fill: #959595;
  }
}
.itemsWrapper {
  display: none;
}

@media (max-width: 650px) {
  .blockMenu {
    .blockMenu__ItemBlock {
      font-size: 16px;
      padding: 10px;
    }
  }

  .blockAvatar {
    height: 41px;
    gap: 7px;

    .blockAvatar__title {
      font-size: 16px;
    }
  }
}


.blockMenuSvg {
  position: relative;
  height: 80px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

.blockWrap {
  z-index: 1;
  width: 24px;
  height: 24px;
}

.svgAbs {
  cursor: pointer;
  position: absolute;
  z-index: 100;
}

.menu__burger {
  display: none;
  position: absolute;
  top: 0;
  width: 200px;
  background: #fff;
  font-size: 20px;
  padding: 113px 0 30px 30px;
  border-radius: 0 0 10px 10px;
  left: -30px;
  flex-direction: column;
  row-gap: 16px;
  box-shadow: 5px 5px 20px #9ECCD8;
}

.blockWrap:hover {
  .menu__burger {
    display: flex;
  }

  .svgAbs path {
    stroke: var(--primary-color);
  }
}

.svg__block_icon {
  z-index: 100;
  cursor: pointer;
  position: relative;
}

@media screen and (min-width: 992px) {
  .blockWrap {
    display: none;
  }
  .itemsWrapper {
    display: flex;
  }
}



