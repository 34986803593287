.Textarea {
  font: var(--font-m);
  border-radius: 10px;
  border: none;
  padding: 15px;
  outline: none;
  background: #eee;
  max-width: 100%;
  min-width: 100%;
}
.charCount {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-size: 12px;
  color: gray;
}
.charCnt{
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-size: 12px;
    color: red;
  }