.Typography {
 //color: #263238;
}

.h1 {
  font: var(--font-xl);
  font-weight: 700;
}

.h2 {
  font: var(--font-l);
  font-weight: 500;
}
.h3 {
  font: var(--font-m);
  font-weight: 600;
}

.bold {
  font: var(--font-s);
  font-weight: 700 !important;
}

.body {
  font: var(--font-s);
  font-weight: 500;
}

.size_s {
  font: var(--font-s);
}

.size_m {
  font: var(--font-m);
}

.size_l {
  font: var(--font-l);
}

.gutterBottom {
  margin-bottom: 16px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
