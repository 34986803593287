.BottomBar {
  position: fixed;
  display: flex;
  align-items: flex-start;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(var(--appbar-height) + env(safe-area-inset-bottom));
  //top: 90%;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px 0px rgba(166, 166, 166, 0.15);
}
.bottomButton{
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  color: #1B708A;
  width: 20%;
  height: var(--appbar-height);
  justify-content: center;
  align-items: center;
}

@media (max-width:310px){
  .bottomButton{
    font-size: 0.7em;
  }  
 }

