.Input {
  font: var(--font-m);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: none;
  padding: 15px;

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &::-webkit-calendar-picker-indicator {
    //display: none;
  }

  &[type="datetime-local"] {
    -webkit-appearance: none;
    -webkit-text-fill-color: #000;
  }

  &:disabled {
    opacity: 0.6;
  }


}

.label {
  color: black;
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  left: 10px;
  top: calc(0rem - var(--font-line-m) / 2);

}

.InputContainer {
  position: relative;
  width: 100%;
}

.fullwidth {
  width: 100%;
}

.outlined {
  background: none;
  outline: 1px solid #e6e6e6;
}

.standart {
  border: none;
  border-radius: 0;
  padding: 5px;
  background: transparent;
  border-bottom: 1px solid #626161;
  transition: all 0.1s ease-in-out;

  &:active,
  &:focus {
    border-bottom: 2px solid var(--primary-color);
    transition: all 0.1s ease-in-out;
  }

  &.error {
    transition: all 0.1s ease-in-out;
    border-bottom: 1px solid var(--secondary-color);

    &:active, &:focus {
      border-bottom: 2px solid var(--secondary-color);
    }
  }

  &::placeholder{
    color: #c7c7c7;
    font-weight: 300;
  }
}

.big {
  font: var(--font-xl);
  font-weight: 500;
  padding: 6px 2px;
  font-size: 32px;
}

.ruble {
  &::before {
    content: "₽";
    font: var(--font-m);
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: center;
  }
}

.textCenter {
  text-align: center;
}

.outlineNone {
  outline: none;
}

@media screen and (max-width: 380px){
  .big{
    font-size: 28px;
  }
}