.SetPaymentButton {
    outline: 2px solid var(--primary-color) !important;
    height: 48;
    text-align: center;
    color: black;
    transition: background-color .3s ;
    transition: all 0.3s ease-in-out;
}
.numbers{
    font-size: 12px;
    text-wrap: nowrap;
}
.desc{
    font-size: 14px;
    text-wrap: nowrap;
}
@media (max-width:400px) {
    .SetPaymentButton{
        flex-direction: column;
    }
}