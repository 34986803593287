.Card {
  background: #FFFFFF;
  //background: linear-gradient(93.69deg, #FFF 0, #FFF 100%);
  color: black;
  box-shadow: 2px 2px 10px rgba(166, 166, 166, 0.2);
  border-radius: 13px;
  padding: 20px 20px;
}
.gradient {
    background: linear-gradient(93.69deg, #C96065 -18.24%, rgba(11, 113, 144, 0.72) 103.4%);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    transform: translate3d(0, 0, 0);
  color: white;
}
.radial{
  background: rgb(99,171,190);
  background: radial-gradient(circle, rgba(99,171,190,1) 0%, rgba(39,102,117,1) 100%);
  color:white
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 50%;
   }
}


