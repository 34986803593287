.wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.mobileWrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.modalTopTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    padding-right: 10px;
    gap: 15px;
    p{
        font-size: 17px;
        line-height: 20px;
        font-weight: 600;   
    }
}

.titleLink{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.buttonClose{
    margin-bottom: 10px;
}
.buttonCloseButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #F4F4F4;
    border-radius: 4px;
    cursor: pointer;
}
@media (max-width:700px) {
    .modalTopTitle{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 10px;
        gap: 15px;
        p{
            font-size: 15px;
            font-weight: 500;
        }        
    }
}