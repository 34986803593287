.phoneWrap{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phoneWrapTitle{
  font-size: 20px;
  line-height: 30px;
  padding-top: 10px;
  padding-bottom: 22px;
  font-weight: 500;
}
.Buttons {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}
.wifi {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 290px;
}

.phoneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 290px;
}
.wifiText{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  font-size: 20px;
  line-height: 29px;
}
.errField{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
}
.errorMessage{
  position: absolute;
  top: 10px;
  font-size: 16px;
  line-height: 30px;
  color: red;
  line-height: 24px;
}
.phoneInputWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
}
.red{
  color: red;  
  input{
    color: red;
    border-bottom: 1px solid red;
  }
}
@media screen and (max-width:440px){
  .wifiText{
    font-size: 20px;
  }
}
