.Avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  //background: var(--primary-color);
  background: var(--gradient-bg-color);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
