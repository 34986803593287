.title{
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  padding-bottom: 10px;
}
.cameraPreview{
width: 100%;
height: 100%;
padding-top: 1vh;
padding-bottom: 1vh;
white-space: nowrap;
text-overflow: ellipsis;
}
.emptyDvr{
  padding: 10px;
  ul{
    padding-left: 20px;
  }
}
.toDvorButton{
  text-decoration: underline;
}
.IntercomInfoBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 76px;
}
.emptyFirstBlock{
  display: flex;
  width: 100%;
  align-items: stretch; 
  justify-content: space-evenly;
  padding: 35px 20px 46px;
  border-bottom: 1px solid #d9d9d9;
  gap: 20px;
}
.emptyTitleDesc{
display: flex; 
flex-direction: column;
height: 338px;
justify-content: space-between;
  p{
     font-size: 14px;
     line-height: 22px;
  }
 }
 .emptyTitle{
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}
.toDvorButton{
  text-decoration: underline;
}
.emptyTitle{
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}
.emptyBlockImage{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.emptyButtons{
  display: flex;
  width: 100%;
  padding: 30px;
  justify-content: space-around;
}
.haveYouIntercom{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0 8px;
  p{
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 16px;
    font-weight: 600;
  }
}
.haveYouIntercomButton{
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  width: fit-content;
  height: 40px;
  padding: 8px 20px;
  margin: 16px;
}

.addressCard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.cardText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  b{
    font-size: 18px;
    line-height: 36px;
    padding-bottom: 20px;
  }
  p{
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 0px;
  }
}
.historyButton{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-wrap: nowrap;
  width: 150px;
  height: 32px;
  border-radius: 42px;
  padding: 4px 8px;
  background: white;
  color: var(--primary-color);
  font-size: 14px;
  margin-bottom: 0px;
  svg{
    min-height: 16px;
    min-width: 16px;
  }
}
.navButton{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframeCamera{
  border-radius: 12px;
  border:none;
  width: 100%;
  max-width: 1200px;
  max-height: 80vh;
  aspect-ratio: 2/1.35;
}
.titleField{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0px;
}

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1); 
    z-index: 9999; 
    animation: slideIn 0.5s forwards; 
    opacity: 0;
  }
  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .fullscreen iframe {
    width: 100%;
    height: calc(100% - env(safe-area-inset-bottom));
    border: none;
  }
  .arrowTitle {
    position: absolute;
    top: 35px;
    left: 0px;
    height: 15px;
  }
  .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 10000;
    height: 40px;
    text-shadow: 1px 1px 2px black;

   svg {
    filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5));
    path {
    scale: 0.9;
    fill: #fff;
  }}
}
.titleAddressField{
  display: flex;
  flex-direction: column;
}
.titlePlace{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 10px;
}    
.buttonCloseButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #F4F4F4;
  border-radius: 4px;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  width: 400px;
  min-width: 400px;
}

.imageWrapper {
  width: 180px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageStyle {
  width: 180px;
  height: 110px;
  object-fit: cover;
}

@media (max-width:500px){
  .imageWrapper {
    width: 150px;
    height: 100px;
  }
  
  .imageStyle {
    width: 150px;
    height: 100px;
    object-fit: cover;
  }
  .imageContainer {
    min-width: 100%;
  }
}

@media (max-width:875px){
  .emptyFirstBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px 26px;

  }
  .emptyTitleDesc{
    height: 100%;
    gap: 20px;
  }
  .emptyButtons{
    flex-direction: column;
  }
  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    width: 100%;
  }
}   
@media (min-width: 700px) {
  .IntercomInfoBlock {
    margin-top: 0px;
   }
  .emptyBlockImage{
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: flex-end;
  }
  .iframeCamera {
    width: 65vw;
    aspect-ratio: 4/3;
  }
  .cardText{
    b{
      padding-bottom: 20px;
    }
    p{
      padding-bottom: 20px;
    }
  }
  .title{
    padding-bottom: 24px;
  }
  .titleField{
    padding: 0px;
    margin-top: 0px;
    justify-content: flex-start;
  }
}
@media (min-width: 1100px) {
  .iframeCamera {
    width: 900px;
    aspect-ratio: 4/2.68;
  }
}