.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.overlay {
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  transition: 0.3s transform;
  transform: scale(0);
  max-width: 90%;
}

.opened {
  z-index: 10;
  pointer-events: auto;
  opacity: 1;

  .content {
    transform: scale(1);
  }
}

.isClosing {
  .content {
    transform: scale(0);
  }
}

.isOpening {
  .content {
    transform: scale(0);
  }
}

.hiddenScroll {
  overflow: scroll;
  align-items: flex-start;
}
.marginTop{
  margin-top: 60px;
}
