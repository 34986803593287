.nameField{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-bottom: 12px;
    font-size: 20px;
    line-height: 30px;
    border-bottom: 1px solid #D8D8D8;
}
.profileImg{
    width: 40px;
    height: 40px;
}
@media (max-width:500px) {
    .nameField{
        font-size: 16px;
        gap: 10px;
    }
    
}
.Form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.emailTitle{
    display: flex;
    padding-left: 20px;
    gap: 12px;
}
.heading {
    margin-bottom: 10px;
}
.editBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
}
.inputContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    border-radius: 0;
    padding: 12px 0;
    width: 100%;
    min-height: 75px;
}
.lastStr{
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 12px 0 0 0;
    width: 100%;
    min-height: 75px;
}

.inputIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #F4F4F4;
    border-radius: 8px;
}

.inputField {
    border: none;
    outline: none;
    color: #767676;
    background: white;
    flex: 1;
    font-size: 14px;
    padding: 8px 0 0 20px ;
}
.inputField:focus{
    background: #F4F4F4;
}
.errorInput {
    border-color: red;
}

.errorText {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    height: 20px;
}
.emailBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.addEmailBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-wrap: nowrap;
    padding: 8px 20px;
    &_mobile{
        background: white;
        border: none;
        box-shadow: none;
    }
}
.errorContainer {
    min-height: 20px;
}