.servicesList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 12px;
}

.addedOnAddress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: var(--primary-color);
  border-radius: 15px;
  color: white;
  padding: 10px 15px 10px 15px;

  span {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  &_center {
    justify-content: center;
  }
}

.onAddress {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: solid 1px var(--primary-color);
  border-radius: 15px;
  color: var(--primary-color);
  padding: 10px 15px 10px 15px;

  span {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

}

