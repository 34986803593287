
@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.arrowTitle {
  display: flex;
  position: relative;
  width: 100%;
  .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 100%;
  }
}

.accidents {
  margin-top: 15px;
  width: 100%;
}

.modalMinSize {
  min-width: 60vh;
  min-height: 30vh;
}

.blockAcc {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: slideIn 1s forwards;
  opacity: 0;
  a {
    margin-top: 5px;
  }
  p {
    padding-bottom: 10px;
    font-weight: 500;
  }
}

.img {
  position: relative;
  cursor: pointer;
}

.poster {
  width: 100%;
  object-fit: cover;
  border-radius: 13px;
}


.controls {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.backButtonField{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
  border-radius: 4px;
  background: #F4F4F4;
  cursor: pointer;
}
.backButton{
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(-1) ;
}
.play {
  width: 60px;
  fill: #FFFFFF;
}

@media screen and (max-width: 585px) {
  .play {
    width: 50px;
  }
}
