.link {
  font-size: 18px;
  display: flex;
  gap: 10px;
  color: #484848;
  align-items: center;
  font-weight: 500;

  &:hover {
    color: var(--primary-color);
  }
}

.active {
  color: var(--primary-color);
}
