.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FCFCFC;
  width: 100vw;
  min-height: 100vh;
  overflow: scroll;
}
.authPage {
  background: #FCFCFC;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 22px;  
  user-select: none;
  &_wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_images{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    p{
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 30px;
    }
  }
  .heading {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      padding: 10px 122px 22px 123px;
      text-align: center;
  }

}
.authTitle{
  font-size: 30px;
  line-height: 48px;
  padding-top: 16px;
  padding-bottom: 12px;
}
.titleImages{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.authImg{
  display: flex;
  gap: 12px;
}
.authCard{
  width: fit-content;
  height: 458px;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;

}
.telOrIp{
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #DFDFDF; 
  position: relative;
}
.text {
  padding: 4px 20px;
  text-align: center; 
  position: relative;
  cursor: pointer;
}
.text.active{
  font-weight: 500;
}
.text.active::after {
  content: ''; 
  position: absolute;
  bottom: -2px; 
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #49A0B4; 
}

.numberCircle {
  font-size: 30px;
  color: #212121;
  font-weight: 500;
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  &__circle {   
    cursor: pointer;
  }
}

.Buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
  &__call{   
    margin-top: 5px; 
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.form {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.freePhone{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.freeTitle{
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}
.freeDetect{
  width: 278px;
  padding-top: 10px;
  font-size: 16px;
  line-height: 22px;
  color:#767676;
}
.phoneInputWrapper{
  input{
    text-align: center;
  }
}
.installApp{
  padding-top: 30px;
  p{
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    padding-bottom: 20px;
  }
}
.appVersion {
  display: flex;
  position: absolute;
  padding-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: rgba(252, 252, 252, 0.1);
  justify-content: center;
  align-items: center;   
}

.cancelBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 290px;
}
.bazaLogo {
  width: 171px;
  height: 132px;
  transform: rotate(-15deg);
  img {
    width: 100%;
  }
}

@media (max-width:1010px){
  .titleImages{
    display: none;
  }
  .authTitle{
    display: none;
  }
  .authPage{
    flex-direction: column;
    align-items: center;
    .heading {
      padding: 10px 30px 22px;
  }
  }
  .authPage_images{
    align-items: center;
  }
}
@media (max-width: 550px) {
  .wrapper{
    justify-content: flex-start;
  }
  .text {
    padding: 4px 5px;
    font-size: 15px;
  }
  .authCard{
    margin:0;
  }
  .authPage{
    padding-top: 5px;
  }
  .telOrIp{
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px solid #DFDFDF; 
    position: relative;
  }
}
