$primary: #49A0B4;
$secondary: #C96065;

.Button {
  border: none;
  padding: 15px;
  font-size: var(--font-size-m);
  font-weight: 500;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  outline: none !important;
  &:disabled {
    background: #ddd;
    &:hover {
     background: #ddd;
    }
    //&:active {
    //  background: #ddd;
    //}
  }

}

.fullwidth {
  width: 100%;
}

.primary {
  background: $primary;
  &:hover {
    background: darken($primary, 7%);
  }
}

.secondary {
  background: $secondary;
  &:hover {
    background: darken($secondary, 7%);
  }
}

.clear {
  //color: var(--link-color);
  background: none;
  box-shadow: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

.gradient {
  background: var(--gradient-bg-color);
}

.radial{
  background: rgb(99,171,190);
  background: radial-gradient(circle, rgba(99,171,190,1) 0%, rgba(39,102,117,1) 100%);
}
.noShadow {
  box-shadow: none;
  background: $primary;
  //&:focus, &:hover {
  //  background: lighten($primary, 7%);
  //  box-shadow: none;
  //}
  //&:active {
  //  background: darken($primary, 7%);
  //  box-shadow: none;
  //}
}
.close{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background: white;
  box-shadow: none;
}

.Button.small {
  padding: 7px;
}

.Button.link {
  color: var(--link-color);
  background: none;
  box-shadow: none;
  padding: 0;
  text-align: left;

  &:disabled {
    background: none;
    color: #7A7A7A;
  }
}

.Button:disabled:hover {
  cursor: auto;
}
