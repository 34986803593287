.wrapper{
    display: flex;
    flex-direction: row;
}

.contractCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    max-width: 392px;
    gap: 30px;
}

.information{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
    margin-bottom: 20px;
}
.mobileSkelet{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    height: 200px;
    margin: 0px 20px 20px;
}
.skeletonText {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.skeletonTextButton {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.skeletButton {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connectSkelet{
    width: 100%;
}
@media (max-width:700px) {
    .contractCard{
        align-items: center;
        width: 100%;
        max-width: 100%;
    }
    .connectToInet{
        width: 80%;
    }
    .wrapper{
        flex-direction: column;
        gap: 0px;
    }
    .cardLoad{
        min-width: 100%;
    }
}