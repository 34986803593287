.wrapper {

}

.pd {
  padding: 25px;
}

.form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
}

.heading {
  margin-bottom: 10px;
}

.successBlock {
  max-width: 400px;
  padding: 10px;
  //text-align: center;
  //margin-top: 10px;
}

.detailsText {
  font: var(--font-s);
  text-align: center;

  a {
    font: var(--font-s);
  }
}

.error {
  color: red;
  padding-left: 10px;
}
.sendButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}