.cardInetTv {
  width: 473px;
  height: 163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;
&_title{
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
}
&_button{
    width: 172px;
    height: 40px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
}

@media (max-width: 930px) {
  .cardInetTv {
    width: 100%;
    &_title{
      font-size: 18px;      
    }    
  }
}
@media (max-width: 860px) {
  .cardInetTv {
    width: 100%;
    &_title{
      display: flex;
      text-align: center;
      font-size: 16px;      
    }    
  }
}
@media (max-width: 760px) {
  .cardInetTv {
    width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    &_title{
      display: flex;
      text-align: center;
      font-size: 16px;  
      line-height: 25px;
     
    }    
    &_text{
      font-size: 12px;
      padding-bottom: 5px;
  }
  }
}
@media (max-width: 700px) {
  .cardInetTv {
    width: 100%;
    &_title{
      font-size: 18px;      
    }    
    &_text{
      font-size: 14px;
      padding-bottom: 5px;
    }
    &_button{
      font-size: 14px;
    }
  }
}