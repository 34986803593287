:root {
  --appbar-bg-color: #fff;
  --appbar-height: 60px;
}

.AppBar {
  width: 100%;
  background: var(--appbar-bg-color);
  height: var(--appbar-height);
  padding: 10px 0;
  //border-bottom: 1px solid #B3B3B3;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
