.Switch {
  height: 40px;
  width: 70px;
  border-radius: 35px;
  border: none;
  //background: rgba(120, 120, 128, 0.16);
  background: #eee;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.SwitchWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checked {
  //background: #34C759;
  background: var(--secondary-color);
}

.srOnly {
  //background: black;
}

.move {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 34px;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100%;
  display: block;
  transition: all 0.3s ease-in-out;
  &Checked {
    left: 33px;
  }
}
