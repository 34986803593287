.PageError {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  padding: 20px;
}

.messageCard {

}
