.cardHomePage {
  cursor: pointer;
  user-select: none;
}

.HomePage {
  padding-top: 0px;
  display: flex;
  flex-direction: row;
}
.uidCard{
  display: flex;
  flex-direction: column;
}
.container{
  display: flex;
  width: 100%;
  padding-left: 53px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &_ads{
    display: flex;
    width: 100%;
  }
  &_title{
    display: flex;
    font-size: 24px;
    line-height: 36px;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    width: 100%;
    font-weight: 500;
    padding-top: 10px;
  }
}
.container_title:first-child{
  padding-top: 0px;
  padding-bottom: 10px;
}
.cardGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
  grid-auto-rows: minmax(124px, auto);
  gap: 24px;
  margin-top: 10px;
}

.tariffInfo {
  padding-bottom: 20px;
  border-bottom: 1px solid #7A7A7A;

  .tariffName {
    color: #7A7A7A;
  }

  .increaseBtn {
    margin-top: 10px;
  }
}

.additionalServices {
  padding-top: 20px;

  .servicesList {
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .connectRequestBtn {
    margin-top: 30px;
  }
}

.cost {
  .digit {
    line-height: 100%;
  }

  .text {
    line-height: 60%;
    color: #7A7A7A;
  }
}
@media (max-width:700px) {
  .HomePage{
    flex-direction: column;
  }
}


@media (max-width: 1220px) {
  
  .container{
    padding: 0 16px;
  } 
  .cardHomePage{
    padding: 20px;
  }
}
@media (max-width: 1047px) {
  .cardGrid {
    grid-gap: 10px;
  }
}
.accList{
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  padding-bottom: 10px;
  padding-left: 16px;
}
