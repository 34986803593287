.CardWithIcon {
  height: 124px;
  padding: 20px 25px;
  overflow: hidden;
}

.flexBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;

  &__Icon {
    width: 60px;
    height: 60px;
    background-size: cover;
  }

  &__Content {
    width: 100%;
  }

  .title{
    text-align: left;
    font-size: 18px;
    line-height: 24px;
  }
  .text {
    margin-top: 5px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #7A7A7A;
    font-weight: 500;
  }
  .iconWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .icon {
  width: 110px;
  height: 105px;
  object-fit: contain;
}

.firstIcon {
  width: 120px;
  height: 80px;
  object-fit: contain;
}
  @media (max-width:1160px) {
    .title{
      font-size: 16px;
    }
    .text{
      font-size: 12px;
    }
}
@media (max-width:1116px) {
  .title{
    font-size: 15px;
  }
  .text{
    font-size: 12px;
  }
}

@media (max-width:1011px) {
  .title{
    font-size: 18px;
  }
  .text {   
    margin-top: 10px; 
    font-size: 14px;
  }
}
@media (max-width:725px) {

  .title{
    font-size: 16px;
  }
  .text {   
    margin-top: 10px; 
    font-size: 12px;
  }
}
@media (max-width:346px) {
  .title{
    font-size: 14px;
  }
  .text {   
    margin-top: 10px; 
    font-size: 12px;
  }  
}
}
@media (min-width:700px) {
  .flexBlock {
    gap: 10px;
}
}