.wrapper {
  display: flex;
  max-width: 400px;
  max-height: 400px;
  flex-direction: column;
  text-align: left;
  padding: 10px;

  .titleBug{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .closeBugButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #F4F4F4;
    border-radius: 4px;
    cursor: pointer;
  }

  form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .heading {
    margin-top: 10px;
  }

  .btn {
    margin: 0 auto;
    width: 25vw;
    min-width: 150px;
    max-width: 250px;
  }
}
.helpPhone{
  text-wrap: nowrap;
}

.successBlock {
  //text-align: center;
  //margin-top: 10px;
}