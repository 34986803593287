.payForm{
    justify-content: space-between;
    gap: 47px;
    width: 100%;
    border: 2px solid #D8D8D8;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0.7;
    transform: scale(0.97);
}
.topCardWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
}
.contractAddressActive{
    padding-bottom: 11px;
    margin-right: 28px; 
    border-bottom: 2px solid var(--primary-color);
    cursor: default;
}
.payFormActive{
    cursor: default;
    border: 2px solid var(--primary-color); 
    opacity: 1;
    transform: scale(1);
    background: linear-gradient(to right, white 50%, #F4F4F4 50%);
}
.circle{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-height: 20px;
    min-width: 20px;
    border: 2px solid var(--primary-color);
    .circleInCircle{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        min-width: 10px;
        min-height: 10px;
        background: var(--primary-color);
    }
    .circleInCircleActive{
        display: none;
    }
}
.circleActive{
    border: 2px solid #D8D8D8;
}
.dogovor{
    font-size: 16px;
    line-height: 24px;
}
.addressFull{
    font-size: 14px;
    line-height: 22px;
}
.bottomPart{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding-top: 17px;
}
.contractAddress{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    line-height: 24px;
    cursor: pointer;
}

.tariffName{
    padding-bottom: 10px;
}
.emailPay{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 18px;
    padding: 0px 28px 0px 0px;
}
.bonuses{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0px 5px;
}
.bonuslink{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
}
.active {
    background-color: var(--primary-color);
    color: white;
}

.itemForm {
    margin-bottom: 20px;
}
.promoButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #ffffff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    max-width: 150px;
    &:hover{
        background: #F4F4F4;
    }
}
.paymentButton {
    height: 40px;
    padding: 4px 30px;
    max-width: 150px;
}
.paymentButtonBonus{
    display: flex;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    max-width: 110px;
    height: fit-content;
}

.wrapperButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 21px;
    position: relative;
    &Padding{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 21px;
        position: relative;
        margin-bottom: 20px;
    }
    &Column{
        flex-direction: column;
        position: relative;        
    }
}
.payForwardSide{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.imagesWrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    max-height: 100px;
    gap: 20px;
    padding-bottom: 8px;
    img{
        height: fit-content;
        width: 40px;
    }
}
.payForwardWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 25px;
    .title{
        font-size: 14px;
        padding-bottom: 6px;
        font-weight: 600;
    }
}
.description{
    font-size: 12px;
    line-height: 22px;
    min-height: 80px;
}
.descriptionFriend{
    font-size: 12px;
    line-height: 22px;
    min-height: 102px;
}
.descriptionAkciya{
    font-size: 12px;
    line-height: 22px;
}
.payForwardLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    width: 100%;
}

.companySvg {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}
.copySvg{
    cursor: pointer;
}

@media (max-width:700px) {
    .payFormActive{
        background: white;
    }
    .emailPay{
        flex-direction: column;
        width: 100%;
        gap: 10px;
        padding: 0;
    }
    .bottomPart{
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        padding-top: 12px;
    }
    .bonuses{
        margin-top: 0px;
    }
}
@media (max-width:530px) {
    .bonuses{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .paymentButtonBonus{
        display: flex;
        flex-direction: column;
        padding: 0;
        max-width: 100%;
        height: 48px;
    }
}
.promoInput{
    min-width: 50px;
}
.pendIcon{
    color: #000000;
    height: 16px;
    width: 16px;
}
.successIcon {
    color: #4CAF50;
    height: 16px;
    width: 16px;
  }
.errorIcon {
    color: #F44336;
  }
  .successText,
.errorText {
    position: absolute;
    bottom: -16px;     
    left: 0;           
    font-size: 14px;
    margin: 0;
}

.successText {
    color: #28a745;
}

.errorText {
    color: #dc3545;
}
.promoWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 21px;
}
@media (max-width:430px) {
    .bonuses{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .paymentButtonBonus{
        padding: 0;
        max-width: 100%;
        height: 48px;
    }
    .emailPay{
    width: 100%;
    }
    .wrapperButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 21px;
    }
    .wrapperButtonPadding{
        margin-bottom: 0px;
    }
    .promoWrapper{
        flex-direction: column;
    }
    .successText,
    .errorText {
        bottom: 46px;     
        left: 0;           
        font-size: 12px;
        margin: 0;
    }
}  