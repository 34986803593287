:root {
  --font-family-main: 'Inter', sans-serif;

  --font-size-s: 14px;
  --font-line-s: 19px;
  --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  --font-size-m: 17px;
  --font-line-m: 22px;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

  --font-size-l: 22px;
  --font-line-l: 27px;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  --font-size-xl: 32px;
  --font-line-xl: 41px;
  --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

  --primary-color: #49A0B4;
  --secondary-color: #C96065;
  --link-color: #007AFF;

  --white-theme:#f4f4f4;
  --gray-color:#F4F4F4;
  

  --black-theme:#404247;


  --bg-color: linear-gradient(180deg, #E4EDF9 0%, #FCFDFF 100%);
  --gradient-bg-color: linear-gradient(93.69deg, #C96065 -18.24%, rgba(11, 113, 144, 0.72) 103.4%);
  //--gradient-bg-color: linear-gradient(93.69deg, #C96065 -18.24%, #0B7190 103.4%);

  // bottom-sheet
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fafafa;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  //--rsbs-ml: 7px;
  --rsbs-ml: 0;
  //--rsbs-mr: 7px;
  --rsbs-mr: 0;
  --rsbs-overlay-rounded: 16px;

  --overlay-color: rgba(0 0 0 / 60%);
}
