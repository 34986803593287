.addUidCard{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.wrapper{
  max-width: 500px;
  min-height: 500px;
  padding: 10px;
  p {
    padding-bottom: 20px;
  }
}
.dopContractCard{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 20px;
}
.plusSvg{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
@media (max-width:700px) {
  .wrapper{
    max-width: none;
    min-height: none;
    padding: 0px 20px 20px ;
  }  
}
.titleWrapper{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 23px;
}
.title{
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
}
.closeButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #F4F4F4;
  border-radius: 4px;
  cursor: pointer;
}
.textField{
  display: flex;
  padding: 20px 0px 16px;
}
.addressPlaceholder {
  min-height: 20px;
  padding: 15px 0px 0px;
  p{
    padding-top: 15px;
  }
}
.findButton{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
