.MainLayout {
  background: var(--bg-color);
  min-height: 100vh;
  overflow-y: auto;
}

.wrapper {
  margin-top: var(--appbar-height);
  padding-top: 40px;
  padding-bottom: calc(70px + env(safe-area-inset-bottom, 20px));
}

.bottomSign > div {
  bottom: calc(58px + env(safe-area-inset-bottom, 20px));
}

@media (max-width:700px) {
  .wrapper {
      padding-top: 0px;
      margin-top: calc(var(--appbar-height) + env(safe-area-inset-top, 20px));
  }  
}
