.assosModal{
    z-index: 99;
}
.noPhoneAlert{
    display: flex;
    width: 100%;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: #63ABBE;
    color: white;
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1vh;
    height: calc(var(--appbar-height) + env(safe-area-inset-bottom));
}
.associateButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}
.arrowDirectionButton{
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrowDirection{
    transform: rotate(180deg);
    path{
        fill:white
    }
}
.alertTitle{
    display: flex;
    align-items: center;
    cursor: pointer;
}
